module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-smoothscroll/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Pisach","short_name":"Pisach","start_url":"/","background_color":"#fff","theme_color":"#000","display":"minimal-ui","icon":"src/images/logo.svg","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"d855ca6e14ab8f1ca0b41cb2b600ce98"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
